<template>
    <div
        v-click-outside="clickedOutside"
        id="regionSelector"
        class="field field-input"
    >
        <div class="control">
            <span
                v-show="!selectMode"
                class="input is-medium selectedRegion"
                @click="isSelectMode"
            >
                {{ getSelectedRegion }}
            </span>
            <div class="tabindex" v-show="selectMode" tabindex="0" @blur="isNotSelectMode">
                <input
                    ref="selectregioninput"
                    v-model="input"
                    class="input input-region is-medium"
                    type="text"
                    :placeholder="inputPlaceHolder"
                    :class="input.length != 0 ? ' open-dropdown' : ''"
                    @focus="
                        inputActive = true;
                        input.length != 0 && $event.target.select();
                    "
                    @keydown="selectRegionByKeyboard($event)"
                />
                <ul
                    class="region-dropdown"
                    tabindex="0"
                    @focus="selectItemsActive = true"
                    @blur="selectItemsActive = false"
                    v-if="inputActive"
                >
                    <li
                        v-for="(region, index) in availableRegions"
                        @mousedown="selectRegion(region)"
                        :class="
                            index === byKeySelectedRegionIndex ? 'selected' : ''
                        "
                    >
                        {{ region.name }}
                    </li>
                    <li
                        v-html="translations['not-in-list']"
                        class="landelijk"
                        :class="
                            byKeySelectedRegionIndex === availableRegions.length
                                ? 'selected'
                                : 'not-selected'
                        "
                        v-if="input.length >= inputLengthTrigger"
                        @click="
                            selectRegion({
                                id: null,
                                name: translations['nationwide'],
                                slug: translations['nationwide'].toLowerCase()
                            })
                        "
                    ></li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
// import eventBus so we can 'communicate' with other components
import { EventBus } from "../vueEventBus.js";
import ClickOutside from "vue-click-outside";

export default {
    props: {
        regions: {},
        currentlyselectedregion: null,
        translations: {
            required: true
        }
    },
    data() {
        return {
            input: "",
            availableRegions: null,
            inputLengthTrigger: 0, // change to 2 or 3 when there are many regions!
            selectedRegion: null,
            selectMode: true,
            selectItemsActive: false,
            inputActive: false,
            byKeySelectedRegionIndex: -1,
            byKeySelectedRegion: null,
            hide: false
        };
    },
    computed: {
        getSelectedRegion() {
            if (this.selectedRegion) {
                return this.selectedRegion.name;
            }
        },
        inputPlaceHolder() {
            return this.selectedRegion && this.selectedRegion.id !== null
                ? this.translations['choose-other-town-or-postal-code']
                : this.translations['choose-town-or-postal-code']
        },
        regionSelector() {
            return document.getElementById('dgm-home-region-select');
        }
    },
    watch: {
        selectedRegion: function() {
            if (this.selectedRegion && this.selectedRegion.buildingtypes.length > 0) {
                document
                    .querySelector("#modalRegion .modal-card-body")
                    .classList.add("card-body-overflow");
            } else {
                document
                    .querySelector("#modalRegion .modal-card-body")
                    .classList.remove("card-body-overflow");
            }
        },
        inputActive: function() {
            if (this.inputActive) {
                document
                    .querySelector("#modalRegion .modal-card-body")
                    .classList.remove("card-body-overflow");
            }
        },
        input() {
            this.filter();
            this.emitRegionInputChanged();
        }
    },
    methods: {
        clickedOutside() {
            this.hide = true;
            this.inputActive = false;
            this.byKeySelectedRegionIndex = -1;
        },
        selectRegionByKeyboard(event) {
            if (event.keyCode == "38") {
                // up arrow
                if (this.byKeySelectedRegionIndex >= -1) {
                    this.byKeySelectedRegionIndex--;
                    this.byKeySelectedRegion = this.availableRegions[
                        this.byKeySelectedRegionIndex
                    ];
                }
            } else if (event.keyCode == "40") {
                // down arrow
                if (
                    this.byKeySelectedRegionIndex <=
                    this.availableRegions.length + 1
                ) {
                    this.byKeySelectedRegionIndex++;
                    this.byKeySelectedRegion = this.availableRegions[
                        this.byKeySelectedRegionIndex
                    ];
                }
            } else if (event.keyCode == "13") {
                // enter

                if (
                    this.availableRegions.length > 0 &&
                    this.byKeySelectedRegionIndex < this.availableRegions.length &&
                    this.byKeySelectedRegion !== null
                ) {
                    this.selectRegion(this.byKeySelectedRegion);
                } else if (this.availableRegions.length === 1 && this.byKeySelectedRegionIndex === -1) {
                    this.selectRegion(this.availableRegions[0]);
                } else if (this.byKeySelectedRegionIndex === this.availableRegions.length) {
                    this.selectRegion({
                        id: null,
                        name: this.translations['nationwide'],
                        slug: this.translations['nationwide'].toLowerCase()
                    });
                } else if (this.input.trim() !== '') {
                    // Start search
                    this.emitRegionStartSearch()
                } else {
                    this.selectRegion({
                        id: null,
                        name: this.translations['nationwide'],
                        slug: this.translations['nationwide'].toLowerCase()
                    });
                }
                this.byKeySelectedRegionIndex = -1;
            } else {
                this.byKeySelectedRegionIndex = -1;
            }
        },
        showSelectedRegion() {
            if (this.selectedRegion && this.selectItemsActive === false) {
                this.selectMode = false;
            }
        },
        selectRegion(region) {
            this.selectedRegion = region;
            this.selectMode = false;
            this.availableRegions = this.regions;
            this.emitRegionSelected();

            if (region.id != null && this.regionSelector) {
                this.regionSelector.scrollIntoView();
            }
        },
        isNotSelectMode() {
            this.selectMode = false;
        },
        isSelectMode() {
            this.selectMode = true;
            const vm = this;
            setTimeout(function() {
                vm.$refs.selectregioninput.focus();
            }, 100);
        },
        emitRegionSelected() {
            EventBus.$emit("region-selected", this.selectedRegion);
        },
        emitRegionInputChanged() {
            EventBus.$emit("region-input-changed", this.input);
        },
        emitRegionStartSearch() {
            EventBus.$emit("region-input-search");
        },
        emitReset() {
            EventBus.$emit("region-selected", null);
        },
        filter: function() {
            if (this.input.length < this.inputLengthTrigger) {
                this.availableRegions = null;
                return;
            }
            this.availableRegions = this.regions.filter(item => {
                return item.name
                    .toUpperCase()
                    .startsWith(this.input.toUpperCase());
            });
        },
    },
    created () {
        EventBus.$on('region-found', (data) => {
            // set received data
            if (data.name) {
                this.input = data.name;

                if (this.regionSelector) {
                    this.regionSelector.scrollIntoView();
                }
            }
        });
        EventBus.$on('reset-region', (data) => {
            this.selectRegion({
                id: null,
                name: this.translations['nationwide'],
                slug: this.translations['nationwide'].toLowerCase()
            });
            this.availableRegions = this.regions;
        });
    },
    mounted() {
        if (this.currentlyselectedregion !== null) {
            this.input = this.currentlyselectedregion.name;
            setTimeout(() => this.selectRegion(this.currentlyselectedregion), 100);
        }
        this.availableRegions = this.regions;
    },
    directives: {
        ClickOutside
    }
};
</script>
