<template>
    <div class="calculator-item range-style">
        <div>
            <label
            ><b>{{ itemData.name }}</b></label
            >
        </div>

        <div class="calculator-input">
            <span class="calculator-icon-wrapper" v-if="itemData.iconLeft">
                <img class="calculator-icon-large" :src="itemData.iconLeft"
                /></span>
            <input
                :style="{ color: mainColor }"
                type="range"
                :min="itemData.min"
                :max="itemData.max"
                v-model="valueRange"
                :step="itemData.increase"
            />
            <span class="calculator-icon-wrapper" v-if="itemData.iconRight">
                <img
                    class="calculator-icon-large calculator-icon-right"
                    :src="itemData.iconRight"
                /></span>
            <span class="calculator-output-wrapper">
                <span class="calculator-output">
                    <span class="is-size-3"
                    ><b style="display:flex;align-items:center;"
                    ><span
                        class="is-size-4"
                        v-if="
                                    itemData.max - itemData.increase <=
                                        valueRange &&
                                        typeof itemData.sliderMaxPopupMessage !=
                                            'undefined'
                                "
                    >>{{ this.itemData.max }}</span
                    >
                            <span v-else>{{ valueRange }}</span></b
                    ></span
                    ><span
                    class="is-size-6 calculator-unit"
                    :style="{ color: mainColor }"
                ><b
                    v-html="
                                valueRange <= 1
                                    ? itemData.unitSingular
                                    : itemData.unitPlural
                            "
                ></b
                ></span>
                </span>
            </span>
        </div>

        <div
            v-if="typeof itemData.sliderMaxPopupMessage != 'undefined'"
            class="modal"
            :class="hitMax ? 'is-active' : ''"
        >
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p v-html="itemData.sliderMaxPopupMessage"></p>
                    <button
                        class="delete"
                        aria-label="close"
                        @click="hitMax = false"
                    ></button>
                </header>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        itemData: {
            type: Object,
            default: {}
        },
        inputValue: {
            type: Object,
            default: {}
        },
        mainColor: {
            type: String,
            default: '#000000'
        },
        subColor: {
            type: String,
            default: '#FFFFFF'
        }
    },
    data() {
        return {
            originalValueRange: 0,
            hitMax: false
        };
    },
    computed: {
        valueRange: {
            set(value) {
                if (this.itemData.max - this.itemData.increase <= value) {
                    this.hitMax = true;
                }
                this.$store.dispatch('saveVariableValue', {
                    name: this.itemData.variableName,
                    value
                });
            },
            get() {
                return this.$store.getters['variableValue'](this.itemData.variableName);
            }
        }
    },
    watch: {
        valueRange(value) {
            this.$emit('change', {
                variableName: this.itemData.variableName,
                value: value
            });
        }
    },
    mounted() {
        this.$store.dispatch('saveVariableValue', {
            name: this.itemData.variableName,
            value: !this.inputValue.value
                ? this.itemData.default
                : this.inputValue.value
        });
    },
};
</script>
