<template>
    <div class="filterComponent" id="filterComponent">
        <nav
            class="navbar filterbar"
            role="filter"
            aria-label="dropdown filter"
        >
            <div class="navbar-start">
                <div class="navbar-item">
                    <div class="select">
                        <select v-model="selectedInstrument">
                            <option
                                :value="null"
                                v-html="
                                    translations[
                                        'select-first-option-instrument'
                                    ]
                                "
                            ></option>
                            <option
                                v-for="item in selectableInstruments"
                                :value="item.id"
                                >{{ item.title }}</option
                            >
                        </select>
                    </div>
                </div>

                <div class="navbar-item">
                    <div class="select">
                        <select v-model="selectedRegion">
                            <option
                                :value="{'item': null, 'group': null}"
                                v-html="
                                    translations['select-first-option-region']
                                "
                            ></option>
                            <option
                                v-for="item in selectableRegions"
                                :value="{'item': item.id, 'group': (item.regiongroupid || null)}"
                                >{{ item.title }}</option
                            >
                        </select>
                    </div>
                </div>

                <div class="navbar-item">
                    <div class="select ">
                        <select
                            v-model="selectedBuildingtype"
                            class="is-capitalized"
                        >
                            <option
                                :value="null"
                                v-html="
                                    translations[
                                        'select-first-option-buildingtype'
                                    ]
                                "
                            ></option>
                            <option
                                v-for="item in selectableBuildingtypes"
                                :value="item.id"
                                >{{ item.title }}</option
                            >
                            <!-- <option
                                v-show="
                                    isSelectableBuildingtype(item.id).length > 0
                                "
                                v-for="item in selectableBuildingtypes"
                                :value="item.id"
                                >{{ item.title }}</option
                            > -->
                        </select>
                    </div>
                </div>
            </div>

            <div class="navbar-end">
                <div
                    @click="showMap = false"
                    :class="!showMap ? 'navbar-item is-active' : 'navbar-item'"
                    id="listViewBtn"
                >
                    <span class="icofont-navigation-menu"></span>
                    <span v-html="translations['list-string']"></span>
                </div>

                <div
                    @click="showMap = true"
                    :class="showMap ? 'navbar-item is-active' : 'navbar-item'"
                    id="mapViewBtn"
                >
                    <span class="icofont-location-pin"></span>
                    <span v-html="translations['map-string']"></span>
                </div>
            </div>
        </nav>

        <div :class="!showMap ? 'is-active' : ''" id="listView">
            <div class="columns is-multiline" v-if="exampleProjects.length > 0">
                <div
                    v-for="item in exampleProjects"
                    class="column is-4 page-item"
                >
                    <div class="block-wrapper">
                        <div
                            class="block-image"
                            :style="getStyle(item.img)"
                        >
                            <a
                                :href="
                                        replaceForCurrentRegion(item.itemurl)
                                    "
                            ></a>
                        </div>
                        <div class="block-content">
                            <h3 class="title">{{ item.title }}</h3>
                            <p>
                                {{ item.regionname }}
                            </p>
                            <a
                                :href="
                                        replaceForCurrentRegion(item.itemurl)
                                    "
                                class="url-arrow-right"
                                v-html="translations['read-more']"
                            ></a>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="exampleprojects-no-results block-wrapper"
                v-if="!exampleProjects || exampleProjects.length == 0"
            >
                <div>
                    <h2 v-html="translations['no-results-message']"></h2>
                    <a
                        v-html="translations['no-results-clear']"
                        class="button is-primary"
                        @click="
                            selectedBuildingtype = null;
                            selectedInstrument = null;
                            selectedRegion = null;
                        "
                    >
                    </a>
                </div>
            </div>
        </div>

        <div id="mapView" :class="showMap ? 'is-active' : ''">
            <div class="columns">
                <div class="column">
                    <div id="map"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import Paginate from 'vuejs-paginate-next';

export default {
    components: {
        paginate: Paginate
    },
    props: {
        measures: {},
        buildingtypes: {},
        regions: {},
        examples: {},
        translations: {},
        activeregion: { type: Number, default: null },
        activeregiongroup: { type: Number, default: null },
        activebuildingtype: { type: Number, default: null },
        activemeasure: { type: Number, default: null },
        activeregionname: ""
    },
    data() {
        return {
            activebuildingtypeslug: null,
            exampleProjects: [],
            selectableInstruments: {},
            selectableRegions: {},
            selectableBuildingtypes: {},
            selectedInstrument: null,
            selectedRegion: null,
            selectedBuildingtype: null,
            selectedBuildingtypeslug: null,
            map: null,
            showMap: false,
            mapMarkers: {},
            activeMarkers: [],
            markerCluster: null,
            mapBounds: null,
            mapLatLngList: [],
            paginate: ["exampleProjectPagination"],
            navigationPage: 0
        };
    },
    computed: {
        currentPage: {
            get() {
                return this.navigationPage;
            },
            set(value) {
                this.navigationPage = value;
            }
        }
    },
    watch: {
        selectedInstrument: function(value) {
            this.filterResults();
            localStorage.setItem("dmg.filter.measure", value);
        },
        selectedRegion: function(value) {
            this.filterResults();
            if (value && value.item) {
                localStorage.setItem("dmg.filter.region", value.item);
            }
            if (value && value.group) {
                localStorage.setItem("dmg.filter.group", value.group);
            }
        },
        selectedBuildingtype: function(value) {
            this.filterResults();
            localStorage.setItem("dmg.filter.buildingtype", value);
            localStorage.setItem("dmg.filter.buildingtypeslug", this.getBuildingTypeSlug(value));
        },
        exampleProjects: function(value) {
            this.clearAllMarkers();

            this.plotMarkers();
        }
    },
    methods: {
        replaceForCurrentRegion: function(url) {
            const replaceIndex = 2;
            const splitUrl = url.split("/");
            const replaceUrl = splitUrl
                .slice(0, replaceIndex)
                .concat(splitUrl.slice(replaceIndex + 1, splitUrl.length));
            replaceUrl.splice(replaceIndex, 0, this.activeregionname);
            let joinedUrl = replaceUrl.join("/");
            if (this.activebuildingtypeslug) {
                joinedUrl += "/?buildingTypeSlug=" + this.activebuildingtypeslug;
            }

            return joinedUrl;
        },
        isSelectableMeasure: function(id) {
            var vm = this;
            var result = _.filter(vm.exampleProjects, function(entry) {
                if (entry.measures.includes(id)) {
                    return true;
                }
            });
            return result;
        },
        isSelectableRegion: function(id, regiongroupid) {
            var vm = this;
            var result = _.filter(vm.exampleProjects, function(entry) {
                if (entry.regionid === id || entry.regiongroupid === regiongroupid) {
                    return true;
                }
            });
            return result;
        },
        isSelectableBuildingtype: function(id) {
            var vm = this;
            var result = _.filter(vm.exampleProjects, function(entry) {
                if (entry.buildingtypeid === id) {
                    return true;
                }
            });
            return result;
        },

        getBuildingTypeSlug: function(id) {
            const row = this.selectableBuildingtypes.find(item => item.id === id);
            this.activebuildingtypeslug = row ? row.slug : null;
        },

        getStyle: function(img) {
            return "background-image: url('" + img + "');";
        },

        filterResults: function() {
            var vm = this;

            var hasSelectedRegion =
                !vm.selectedRegion || vm.selectedRegion.item === null || vm.selectedRegion.item === 0
                    ? false
                    : true;

            var hasSelectedInstrument =
                vm.selectedInstrument === null || vm.selectedInstrument === 0
                    ? false
                    : true;

            var hasSelectedBuildingtype =
                vm.selectedBuildingtype === null ||
                vm.selectedBuildingtype === 0
                    ? false
                    : true;

            var result;

            if (
                hasSelectedBuildingtype ||
                hasSelectedRegion ||
                hasSelectedInstrument
            ) {
                var hasSelected = {
                    regionOnly: 4,
                    regionAndInstrumentOnly: 4,
                    regionAndBuildingtypeOnly: 4,
                    buildingtypeOnly: 4,
                    instrumentOnly: 4,
                    instrumentAndBuildingTypeOnly: 4
                };

                result = _.filter(this.examples, function(entry) {
                    // Single checks (if only bulding/region or measure selected)
                    if (
                        hasSelectedBuildingtype &&
                        !hasSelectedRegion &&
                        !hasSelectedInstrument
                    ) {
                        if (entry.buildingtypeid === vm.selectedBuildingtype) {
                            return true;
                        }
                    }

                    if (
                        hasSelectedInstrument &&
                        !hasSelectedRegion &&
                        !hasSelectedBuildingtype
                    ) {
                        if (entry.measures.includes(vm.selectedInstrument)) {
                            return true;
                        }
                    }

                    if (
                        hasSelectedRegion &&
                        !hasSelectedInstrument &&
                        !hasSelectedBuildingtype
                    ) {
                        if (entry.regionid === vm.selectedRegion.item || entry.regionid === vm.selectedRegion.group) {
                            return true;
                        }
                    }

                    // Double checks (if only building AND region or measure AND etc selected)

                    if (
                        hasSelectedRegion &&
                        hasSelectedBuildingtype &&
                        !hasSelectedInstrument
                    ) {
                        if (
                            (entry.regionid === vm.selectedRegion.item || entry.regionid === vm.selectedRegion.group) &&
                            entry.buildingtypeid === vm.selectedBuildingtype
                        ) {
                            return true;
                        }
                    }

                    if (
                        hasSelectedInstrument &&
                        hasSelectedBuildingtype &&
                        !hasSelectedRegion
                    ) {
                        if (
                            entry.measures.includes(vm.selectedInstrument) &&
                            entry.buildingtypeid === vm.selectedBuildingtype
                        ) {
                            return true;
                        }
                    }

                    if (
                        hasSelectedInstrument &&
                        hasSelectedRegion &&
                        !hasSelectedBuildingtype
                    ) {
                        if (
                            entry.measures.includes(vm.selectedInstrument) &&
                            (entry.regionid === vm.selectedRegion.item || entry.regionid === vm.selectedRegion.group)
                        ) {
                            return true;
                        }
                    }

                    // Triple check (if all types selected)

                    if (
                        hasSelectedInstrument &&
                        hasSelectedRegion &&
                        hasSelectedBuildingtype
                    ) {
                        if (
                            entry.measures.includes(vm.selectedInstrument) &&
                            (entry.regionid === vm.selectedRegion.item || entry.regionid === vm.selectedRegion.group) &&
                            entry.buildingtypeid === vm.selectedBuildingtype
                        ) {
                            return true;
                        }
                    }
                });
            } else {
                result = this.examples;
            }

            this.exampleProjects = result;
        },

        plotMarkers: function() {
            // Hier afhangen of dit in Toolbox of DGM is voor marker
            this.activeMarkers = [];
            this.clearAllMarkers();

            var customIcon = "/img/dgm/marker-dgm.svg";
            for (var project of this.exampleProjects) {
                var contentString = `<div id="content">
	                    <div id="siteNotice">
		                    <h2 id="firstHeading" class="firstHeading">${
                                project.title
                            }</h2>
                                <div id="bodyContent">
                                    <p>Gemeente ${project.regionname}</p>
                                    <img src="${
                                        project.img
                                    }" style="height: 200px"/>
                                    <h5>
                                        <a style="font-size:15px;" href="${this.replaceForCurrentRegion(
                                            project.itemurl
                                        )}"
                                            >${
                                                this.translations["read-more"]
                                            }</a
                                        >
                                    </h5>
                                </div>
                            </div>
                        </div>`;

                if (!project.location || project.location.latitude === '' || project.location.longitude === '') {
                    continue;
                }

                const marker = new google.maps.Marker({
                    position: {
                        lat: project.location.latitude,
                        lng: project.location.longitude
                    },
                    icon: customIcon,
                    map: this.map,
                    title: project.title,
                    animation: google.maps.Animation.DROP
                });

                const infowindow = new google.maps.InfoWindow({
                    content: contentString
                });

                marker.addListener("click", function() {
                    infowindow.open(marker.get("map"), marker);
                });

                this.mapMarkers[project.id] = marker;
                this.activeMarkers.push(marker);
            }
            this.markerCluster = new MarkerClusterer(
                this.map,
                this.activeMarkers,
                {
                    styles: [
                        {
                            textColor: "black",
                            height: 48,
                            width: 32,
                            anchorText: [-8, 0],
                            url: "/img/dgm/marker-dgm-cluster.svg"
                        }
                    ],

                    imagePath: "/img/dgm/marker-dgm-cluster"
                }
            );

            var vm = this;
            setTimeout(function() {
                if (vm.activeMarkers.length > 0) {
                    if (vm.selectedRegion) {
                        vm.map.setZoom(13);
                        vm.map.panTo(vm.activeMarkers[0].position);
                    } else {
                        vm.map.panTo({ lat: 52.185852, lng: 5.270391 });
                        vm.map.setZoom(7);
                    }
                }
            }, 100);
        },

        removeMarker: function(exampleId) {
            this.mapMarkers[exampleId].setMap(null);
        },

        clearAllMarkers() {
            var vm = this;
            Object.keys(vm.mapMarkers).forEach(function(item) {
                vm.removeMarker(item);
                vm.markerCluster.setMap(null);
            });
        },
        getLocalStorageValue: function(key) {
            if (localStorage.getItem(key)) {
                return localStorage.getItem(key);
            }
            return null;
        }
    },
    mounted() {
        const vm = this;
        // /* Copy props to data, since we should never change props */
        this.exampleProjects = this.examples;
        this.selectableInstruments = this.measures;
        this.selectableRegions = this.regions;
        this.selectableBuildingtypes = this.buildingtypes;

        // check if id is in selectable
        if (_.find(this.regions, { id: this.activeregion })) {
            vm.selectedRegion = JSON.parse(JSON.stringify({'item': vm.activeregion, 'group': vm.activeregiongroup}));
        }

        if (_.find(this.buildingtypes, { id: this.activebuildingtype })) {
            vm.selectedBuildingtype = vm.activebuildingtype;
        }

        // /* if we used a filter before, the last knows value was stored. Get it, set it */
        // Check if previous url is a subpage of voorbeeldprojecten or example projects
        var previousPage = JSON.parse(localStorage.getItem("previousPages"))[1];

        if (
            (previousPage && previousPage.includes("voorbeeldprojecten/")) ||
            (previousPage && previousPage.includes("exampleprojects/"))
        ) {
            if (
                typeof this.getLocalStorageValue("dmg.filter.measure") !==
                    "undefined" &&
                !isNaN(this.getLocalStorageValue("dmg.filter.measure")) &&
                localStorage.getItem("dmg.filter.measure")
            ) {
                this.selectedInstrument = Number(
                    this.getLocalStorageValue("dmg.filter.measure")
                );
            } else {
                this.selectedInstrument = null;
            }

            if (
                typeof this.getLocalStorageValue("dmg.filter.buildingtype") !==
                    "undefined" &&
                !isNaN(this.getLocalStorageValue("dmg.filter.buildingtype")) &&
                localStorage.getItem("dmg.filter.buildingtype")
            ) {
                this.selectedBuildingtype = Number(
                    this.getLocalStorageValue("dmg.filter.buildingtype")
                );
            } else {
                this.selectedBuildingtype = null;
            }

            if (
                typeof this.getLocalStorageValue("dmg.filter.buildingtypeslug") !==
                "undefined" &&
                !isNaN(this.getLocalStorageValue("dmg.filter.buildingtypeslug")) &&
                localStorage.getItem("dmg.filter.buildingtypeslug")
            ) {
                this.selectedBuildingtypeslug = String(
                    this.getLocalStorageValue("dmg.filter.buildingtypeslug")
                );
            } else {
                this.selectedBuildingtypeslug = null;
            }

            if (!vm.selectedRegion) {
                vm.selectedRegion = {
                    item: null,
                    group: null
                }
            }

            if (
                typeof this.getLocalStorageValue("dmg.filter.region") !== "undefined" &&
                !isNaN(this.getLocalStorageValue("dmg.filter.region")) &&
                localStorage.getItem("dmg.filter.region")
            ) {
                const regionStorage = Number(this.getLocalStorageValue("dmg.filter.region"));

                // Reset region if 'Nederland'
                if (regionStorage === 237) {
                    vm.selectedRegion = {
                        item: null,
                        group: null
                    }
                    localStorage.removeItem("dmg.filter.region");
                } else {
                    this.selectedRegion.item = regionStorage;
                }
            } else if (localStorage.hasOwnProperty('dmg.filter.region')) {
                this.selectedRegion.item = null;
            }

            if (
                typeof this.getLocalStorageValue("dmg.filter.group") !== "undefined" &&
                !isNaN(this.getLocalStorageValue("dmg.filter.group")) &&
                localStorage.getItem("dmg.filter.group")
            ) {
                this.selectedRegion.group = Number(
                    this.getLocalStorageValue("dmg.filter.group")
                );
            } else if (localStorage.hasOwnProperty('dmg.filter.group')) {
                this.selectedRegion.group = null;
            }
        }

        /* Initialize the map */
        this.map = new google.maps.Map(document.getElementById("map"), {
            zoom: 7,
            streetViewControl: false,
            mapTypeId: "terrain",
            mapTypeControl: false,
            center: { lat: 52.185852, lng: 5.270391 }
        });

        this.mapBounds = new google.maps.LatLngBounds();
        /* Plot the inital markers on the map */

        this.plotMarkers();
    }
};
</script>
