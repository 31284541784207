/**
 * Page navigation model, used for all pages in the tree from Region within Vue measureContentComponent
 * @param data
 * @constructor
 */

export default function Page(data = {'mainCategory': null, 'subCategory': null, 'measure': null}) {

    this.mainCategory = data.mainCategory ? data.mainCategory : null
    this.subCategory = data.subCategory ? data.subCategory : null
    this.measure = data.measure ? data.measure : null

    this.getLevel = function() {
        // check for level 3
        if (this.mainCategory !== null && this.subCategory !== null && this.measure !== null) {
            return 3;
        }

        // check for level 2
        if (this.mainCategory !== null && this.subCategory !== null) {
            return 2;
        }

        // check for level 1
        if (this.mainCategory !== null ) {
            return 1;
        }

        // root level
        return 0;
    }
}