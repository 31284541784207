(function() {
    /* Title scroller homepage */
    var titleSlider = document.getElementsByClassName("landing-title-options-box"); // ul

    if (titleSlider.length) {
        var titleSliderList = titleSlider[0].children;
        var currentItem = 0;

        setInterval(function() {
            var transform = currentItem * 65; // height li = 65px
            titleSlider[0].style.transform = `translateY(-${transform}px)`;

            currentItem = (currentItem + 1) % titleSliderList.length;
        }, 2000);
    }
})();
