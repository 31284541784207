<template>
    <div :style="{ 'min-height': minHeightWrapper + 'px' }">
        <!-- v-if="active.mainCategory === null" -->

        <div class="columns region-main-video">
            <div class="column is-7 region-video-wrapper">
                <div class="is-hidden-tablet">
                    <img
                        class="region-still-image-mobile"
                        :src="content.region.buildingVideo.mainStillImage"
                    />
                    <div
                        class="house__sign"
                        v-if="content.region.buildingVideo.mainNameSign"
                    >
                        <img
                            class="house__sign__pole"
                            :src="assets[0]"
                            alt=""
                        />
                        <img
                            class="house__sign__name"
                            :src="content.region.buildingVideo.mainNameSign"
                            alt=""
                        />
                    </div>
                </div>
                <div
                    v-if="content.region.buildingVideo.mainVideo"
                    class="is-hidden-mobile is-relative"
                >
                    <div
                        class="house__sign"
                        v-if="content.region.buildingVideo.mainNameSign"
                    >
                        <img
                            class="house__sign__pole"
                            :src="assets[0]"
                            alt=""
                        />
                        <img
                            class="house__sign__name"
                            :src="content.region.buildingVideo.mainNameSign"
                            alt=""
                        />
                    </div>

                    <video
                        :src="content.region.buildingVideo.mainVideo"
                        preload="auto"
                        class="transition-video"
                        autoplay
                        loop
                        muted
                    >
                        {{ translations["no-support"] }}
                    </video>

                    <div class="tablet-screen">
                        <img
                            class="region-still-image-mobile"
                            :src="content.region.buildingVideo.mainStillImage"
                        />
                    </div>

                    <div class="house-arrow -top">
                        <div class="house-arrow-copy">
                            {{ translations["check-possibilities"] }}
                        </div>

                        <svg
                            version="1.1"
                            id="Layer_1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="0 0 256 256"
                            style="enable-background:new 0 0 256 256;"
                            xml:space="preserve"
                        >
                            <path
                                d="M254.1,61.9c4.4-21.2-8.5-25.6-12.9-4.3c-13,62.9-91.6,99.5-160.8,96.8c10.6-7.1,20.6-15,29.8-24.4
                        	c12.7-13.1,9.4-27.5-8.1-17.2c-26.5,15.7-63.8,20.6-90.3,36.2c-9.7,5.8-12.2,17.4-5.1,26.4c16.2,20.6,42,35.6,60.7,54
                        	c15.5,15.3,32.4-2.8,16.9-18.1c-8.2-8.1-16.1-16.3-23.7-24.8C144.5,196.2,236.9,145.3,254.1,61.9z"
                            />
                        </svg>
                    </div>

                    <div
                        v-if="
                            content.region.buildingVideo.mainStillImage &&
                                !content.region.buildingVideo.mainVideo
                        "
                        class="has-text-centered"
                    >
                        <img
                            :src="content.region.buildingVideo.mainStillImage"
                            alt=""
                        />
                    </div>
                </div>
            </div>
            <div
                class="column block-container"
                :style="
                    active.mainCategory === null
                        ? { opacity: 1 }
                        : { opacity: 0 }
                "
                :class="active.mainCategory === null ? '' : 'is-hidden-mobile'"
            >
                <h1>{{ content.region.pageTitle }}</h1>
                <span class="subtitle">{{ content.region.pageSlogan }}</span>

                <div
                    class="description"
                    v-html="content.region.pageDescription"
                ></div>

                <div class="scroll-down">
                    {{ translations["scroll-down-for-more-info"] }}
                    <span>
                        <i class="icofont-thin-down"></i>
                    </span>
                </div>
            </div>
        </div>

        <!-- Main Category -->
        <div
            :style="
                active.mainCategory !== null
                    ? {
                          opacity: 1,
                          position: '',
                          top: 0,
                          transform: 'translateY(-0.75rem)'
                      }
                    : {
                          opacity: 1,
                          position: '',
                          transform: 'translateY(-0.75rem)'
                      }
            "
        >
            <div
                class="region-building-videos"
                v-if="active.mainCategory !== null"
                v-for="(mainCategory, index) in content.children"
            >
                <div
                    class="columns"
                    v-show="active.mainCategory === mainCategory.slug"
                >
                    <div class="column is-7 region-video-wrapper">
                        <measure-building-component
                            :translations="translations"
                            v-on:change-color="catchActiveIndexChange"
                            :active-category="active.mainCategory"
                            :video-data="content.region.buildingVideo"
                            :still-image="
                                content.region.buildingVideo.mainStillImage
                            "
                            :main-color="
                                content.children[activeIndex].mainColor
                            "
                            :active-index="index"
                            :active-slug="mainCategory.slug"
                        ></measure-building-component>
                    </div>

                    <div class="column block-container">
                        <h1>{{ mainCategory.title }}</h1>
                        <span class="subtitle">{{ mainCategory.slogan }}</span>

                        <div
                            class="description"
                            v-html="mainCategory.description"
                        ></div>

                        <div class="scroll-down">
                            {{ translations["scroll-down-for-more-info"] }}
                            <span>
                                <i class="icofont-thin-down"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import eventBus so we can 'communicate' with other components
import { EventBus } from "../../vueEventBus.js";
import Page from "../../../models/page.js";
import measureBuildingComponent from "./measureBuildingComponent.vue";

export default {
    components: {
        "measure-building-component": measureBuildingComponent
    },
    props: {
        content: {},
        activePage: {
            default: function() {
                return new Page({
                    mainCategory: null,
                    subCategory: null,
                    measure: null
                });
            }
        },
        assets: {
            default: [],
            type: Array
        },
        translations: {
            default: {}
        }
    },
    data() {
        return {
            active: this.activePage,
            mainColorDelayed: "",
            activeIndex: 0,
            activeSlug: null,
            minHeightWrapper: 800
        };
    },
    methods: {
        redirect(slug) {
            // window.location = '/' + this.region.locale + '/' + this +'/' + this.region.slug + '/' + slug
        },
        setActivePage(page, id) {
            this.active = {
                page: page,
                id: id
            };
        },
        catchActiveIndexChange: function(input) {
            this.activeSlug = input.activeSlug;
            if (this.active.mainCategory === this.activeSlug) {
                this.activeIndex = input.activeIndex;
            }
            this.setWrapperHeight();
        },
        setWrapperHeight: function() {
            // var regionMainVideoHeight = this.$el.querySelector(
            //     ".region-main-video"
            // ).offsetHeight;
            var regionMainVideoHeight = this.$el.querySelector(
                ".region-video-wrapper"
            ).children[1].offsetHeight;
            // alert(regionMainVideoHeight);

            this.minHeightWrapper = regionMainVideoHeight;
            let uri = window.location.href.split("#");
            if (uri.length == 2) {
                var regionBuilingVideosHeight = this.$el.querySelector(
                    ".region-building-videos"
                ).offsetHeight;
                if (regionBuilingVideosHeight >= regionMainVideoHeight) {
                    this.minHeightWrapper = regionBuilingVideosHeight;
                }
            }
        }
    },
    mounted() {
        setTimeout(() => {
            this.setWrapperHeight();
        }, 500);
        var vm = this;
        window.addEventListener("resize", function() {
            vm.setWrapperHeight();
        });
    },
    created() {
        EventBus.$on("navigateTo", data => {
            this.active = data;
        });

        // set state upon new request
        let uri = window.location.href.split("#");
        if (uri.length == 2) {
            this.active.mainCategory = uri[1];
        }
    },
    watch: {}
};
</script>
