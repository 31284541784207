function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

function eraseCookie(name) {
    document.cookie = name + "=; Max-Age=-99999999;";
    blockYoutubeEmbeds();
}

function acceptCookie() {
    setCookie("dgg-cookie", "true", 365);
    document.getElementById("cookiebar").style.display = "none";
    document.getElementById("cookiebar-modal").classList.remove("is-active");
}

function rejectCookie() {
    setCookie("dgg-cookie", "false", 365);
    document.getElementById("cookiebar").style.display = "none";
    document.getElementById("cookiebar-modal").classList.remove("is-active");
}

function blockYoutubeEmbeds() {
    var iframes = document.querySelectorAll("iframe");

    function wrap(el, wrapper) {
        el.parentNode.insertBefore(wrapper, el);
        wrapper.appendChild(el);
        wrapper.classList.add("blocked-youtube-wrapper");
    }

    iframes.forEach(function(element) {
        if (element.src.includes("youtube.")) {
            element.classList.add("blocked-youtube");
            wrap(element, document.createElement("div"));
        }
    });
}

function unblockYoutubeEmbeds() {
    var iframes = document.querySelectorAll("iframe");

    iframes.forEach(function(element) {
        if (element.src.includes("youtube.")) {
            element.classList.remove("blocked-youtube");
        }
    });
}

// display cookie bar if no cookie

document.addEventListener("DOMContentLoaded", function(event) {
    var cookie = getCookie("dgg-cookie");
    if (cookie === null) {
        document.getElementById("cookiebar").style.display = "block";
        blockYoutubeEmbeds();
    }

    // if cookie rejected

    if (cookie === "false") {
        blockYoutubeEmbeds();
    }
});

// handle accept cookie

document
    .querySelectorAll("#cookie-accept, #cookie-accept-modal")
    .forEach(function(element) {
        element.addEventListener("click", function(event) {
            acceptCookie();
            unblockYoutubeEmbeds();
            document
                .getElementById("cookiebar-modal")
                .classList.remove("is-active");
        });
    });

// handle reject cookie

document.querySelectorAll("#cookie-reject").forEach(function(element) {
    element.addEventListener("click", function(event) {
        rejectCookie();
        blockYoutubeEmbeds();
        document
            .getElementById("cookiebar-modal")
            .classList.remove("is-active");
    });
});

// if click youtube embed and cookies not accepted display modal

document.addEventListener(
    "click",
    function(event) {
        // If the clicked element doesn't have the right selector, bail
        if (!event.target.matches(".blocked-youtube-wrapper")) return;

        // Don't follow the link
        event.preventDefault();

        // Log the clicked element in the console
        var cookie = getCookie("dgg-cookie");
        if (cookie === null || cookie === "false") {
            document
                .getElementById("cookiebar-modal")
                .classList.add("is-active");
        }
    },
    false
);

// close button cookie modal
document
    .querySelectorAll(".cookie-accept-modal-close")
    .forEach(function(element) {
        element.addEventListener(
            "click",
            function(event) {
                document
                    .getElementById("cookiebar-modal")
                    .classList.remove("is-active");
            },
            false
        );
    });
