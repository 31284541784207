<template>
    <div>
        <div
            @click="
                getSavedMeasuresThis ? (showModalForget = true) : saveMeasure();
                !showModalForget ? (showModalSaved = true) : '';
            "
        >
            <button
                v-if="uiStyle === 'button'"
                class="button is-primary has-icon-left measure-block-title-button"
                :class="!getSavedMeasuresThis ? '' : 'is-outlined'"
            >
                <span class="button-icon">
                    <i class="icofont-star"></i>
                </span>
                <span>{{ uiStyleString }}</span>
            </button>

            <span
                v-if="uiStyle === 'span'"
                class="measure-meta-button toggle-button"
                :class="!getSavedMeasuresThis ? 'not-saved-item' : ''"
            >
                {{ uiStyleString }}

                <span><i class="icofont-star"></i></span>
            </span>

            <a
                href="#"
                class="saved-measure-delete"
                v-if="uiStyle === 'remove'"
            >
                {{ uiStyleString }}
                <span><i class="icofont-close-circled"></i></span>
            </a>
        </div>
        <div class="modal" :class="showModalForget ? ' is-active' : ''">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <div>
                        <p
                        > {{ translations.remove.body }}</p>
                        </br>
                        <button
                            class="button is-primary "
                            @click="showModalForget = false; forgetMeasure()"
                        >{{ translations.remove.confirm }}</button>
                          <button
                            class="button is-secondary "
                            @click="showModalForget = false;"
                        >{{ translations.remove.cancel }}</button>
                        <button
                            class="delete"
                            aria-label="close"
                            @click="showModalForget = false"
                        ></button>
                    </div>
                </header>
            </div>
        </div>

        <div class="modal " :class="showModalSaved && !showModalForget ? 'is-active' : ''">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <div>
                        <h2>{{translations.save.title}}</h2>
                        <p>{{translations.save.body}}</p>

                        </br>
                        <button
                            class="button is-primary "
                            @click="showModalSaved = false"
                        >{{translations.save.confirm}}</button>
                        <button
                            class="delete"
                            aria-label="close"
                            @click="showModalSaved = false"
                        ></button>
                    </div>
                </header>
            </div>
        </div>
    </div>
</template>

<script>
import calculatorRangeComponent from "./calculatorRangeComponent.vue";
import calculatorRadioComponent from "./calculatorRadioComponent.vue";
import calculatorNumberComponent from "./calculatorNumberComponent.vue";
import calculatorResultsComponent from "./calculatorResultsComponent.vue";

export default {
    props: {
        uiStyle: {
            type: String,
            default: "button"
        },
        uiStyleString: {
            type: String,
            default: ""
        },
        translations: {
            type: Object,
            default: null
        },
        uiOnlyRemove: {
            type: Boolean,
            default: false
        },
        measureId: {
            type: Number,
            default: null
        },
        measureRegion: {
            type: Number,
            default: null
        },
        measureBuildingtype: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            showModalForget: false,
            showModalSaved: false
        };
    },
    computed: {
        getSavedMeasures() {
            return this.$store.state.savedMeasures;
        },
        getSavedMeasuresThis() {
            if (this.$store.state.savedMeasures) {
                return this.$store.state.savedMeasures[this.measureId];
            }
        }
    },
    methods: {
        forgetMeasure: function() {
            this.$store.dispatch("forgetMeasure", this.measureId);

            // LOGIC FOR BEWAAR PAGINA. On click 'verwijder' also remove 'saved-measure' parent
            if (this.uiStyle === "remove") {
                this.$el.closest(".saved-measure").remove();
            }
        },
        saveMeasure: function() {
            var rightNow = new Date();
            var res = rightNow
                .toISOString()
                .slice(0, 10)
                .replace(/-/g, "-");
            this.$store.dispatch("saveMeasure", {
                id: this.measureId,
                content: {
                    dateSaved: res,
                    region: this.measureRegion,
                    buildingType: this.measureBuildingtype,
                    calculations: {}
                }
            });
        }
    }
};
</script>
