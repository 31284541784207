<template>
    <nav class="sidebar-menu">
        <ul>
            <li
                :class="
                    isActive()
                        ? 'sidebar-menu-item stay-active active'
                        : 'sidebar-menu-item stay-active'
                "
            >
                <div class="line"></div>
                <a
                    @click="navigateToMeasuresHome"
                    :href="
                        '/' +
                            locale +
                            '/' +
                            activeRegion.slug +
                            '/' +
                            activeBuildingType.slug
                    "
                >
                    <span><i class="icofont-info"></i></span>
                    {{ title }}
                </a>

                <measure-main-cat-nav-item-component
                    :main-categories="mainCategories"
                    :active-region="activeRegion"
                    :active-building-type="activeBuildingType"
                    :active-measure-main-category="activeMeasureMainCategory"
                    :active-measure-sub-category="activeMeasureSubCategory"
                    :locale="locale"
                    :is-mobile-navigation="isMobileNavigation"
                ></measure-main-cat-nav-item-component>
            </li>
        </ul>
    </nav>
</template>

<script>
// import eventBus so we can 'communicate' with other components
import { EventBus } from "../vueEventBus.js";
import measureMainCatNavItemComponent from "../components/measure/measureMainCatNavItemComponent.vue";
import Page from "../../models/page.js";

export default {
    components: {
        measureMainCatNavItemComponent
    },
    props: {
        navigation: {
            default: {}
        },
        activeRegion: {
            required: true
        },
        activeBuildingType: {
            required: true
        },
        locale: {
            default: "nl"
        },
        activeMeasureMainCategory: {
            default: null
        },
        activeMeasureSubCategory: {
            default: null
        },
        isMobileNavigation: {
            default: false
        }
    },
    data() {
        return {
            title: null,
            mainCategories: null
        };
    },
    methods: {
        navigateToMeasuresHome() {
            EventBus.$emit(
                "navigateTo",
                new Page({
                    child: null,
                    grandChild: null,
                    grandGrandChild: null
                })
            );
        },
        isActive() {
            return window.location.pathname.includes(
                "/" +
                    this.locale +
                    "/" +
                    this.activeRegion.slug +
                    "/" +
                    this.activeBuildingType.slug
            );
        }
    },
    mounted() {
        this.title = this.navigation.title;
        this.mainCategories = this.navigation["main-categories"];
    }
};
</script>
