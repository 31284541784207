<template>
    <div>
        <div class="region-search-btn">
            <p><a class="button is-primary-light" @click="search">{{ translations.search }}</a></p>
        </div>
    </div>
</template>

<script>
// import eventBus so we can 'communicate' with other components
import { EventBus } from '../vueEventBus.js';
import axios from 'axios';
export default {
    props: {
        translations: {
            default: null
        },
        locale: {
            default: 'nl'
        },
        path: {
            default: '/',
            type: String
        },
    },
    data() {
        return {
            input: "",
        }
    },
    methods: {
        search() {
            const formData = new FormData();
            formData.append('search', this.input);
            axios.post(this.path,formData, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
            }).then(response => {
                const data = response.data;
                if (data.region) {
                    EventBus.$emit("region-selected", data.region);
                    EventBus.$emit("region-found", data.region);
                }
            }).catch(error => {
                const data = error.toJSON();
                if (data.message) {
                    EventBus.$emit("reset-region");
                    if(this.input){
                        EventBus.$emit("region-not-found", this.input);
                    }
                }
            })
        }
    },
    created (){
        EventBus.$on('region-selected', (data) => {
            if(data.id){
                this.input = data.name;
            }else{
                this.input = null;
            }
        });
        EventBus.$on('region-input-changed', (data) => {
            this.input = data;
        });
        EventBus.$on('region-input-search',() => {
            this.search();
        });
    },
    mounted() {
    }
}
</script>