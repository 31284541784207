<template>
    <div class="region-field field">
        <ul class="typologies-title has-text-centered">
            <li>
                <strong
                    >{{ translations["choose-buildingtype"] }}
                    <template v-if="region.id != null">
                        {{ translations["choose-buildingtype-preposition"] }}
                        {{ region.name }}
                    </template>
                </strong>
            </li>
        </ul>

        <div class="columns is-multiline">
            <div
                class="column is-4"
                v-for="fbt in selectedRegion.buildingtypes"
            >
                <div class="modal-typologies">
                    <a
                        :href="`/${locale}/${selectedRegion.slug}/${fbt.slug}`"
                        @click="redirect(fbt.slug)"
                        :class="
                            `typologies-item typologies-item-${fbt.singular}`
                        "
                    >
                        <span class="typologies-label">{{ fbt.singular }}</span>
                        <img v-if="fbt.backimg" :src="fbt.backimg" />
                        <img
                            v-if="fbt.frontimg"
                            :src="fbt.frontimg"
                            :alt="fbt.singular"
                        />
                    </a>
                </div>
            </div>
        </div>

        <ul class="typologies-title has-text-centered">
            <li>
                <strong>{{
                    translations["alternative-building-types"]
                }}</strong>
            </li>
        </ul>

        <div class="columns is-multiline">
            <div
                class="column is-4"
                v-for="alternativeBuildingType in alternativeBuildingTypes"
            >
                <div class="modal-typologies">
                    <a
                        :href="
                            `/${locale}/landelijk/${alternativeBuildingType.slug}`
                        "
                        @click="redirect(alternativeBuildingType.slug)"
                        :class="
                            `typologies-item typologies-item-${alternativeBuildingType.singular}`
                        "
                    >
                        <span class="typologies-label">{{
                            alternativeBuildingType.singular
                        }}</span>
                        <img
                            v-if="alternativeBuildingType.backimg"
                            :src="alternativeBuildingType.backimg"
                        />
                        <img
                            v-if="alternativeBuildingType.frontimg"
                            :src="alternativeBuildingType.frontimg"
                            :alt="alternativeBuildingType.singular"
                        />
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import eventBus so we can 'communicate' with other components
import { EventBus } from "../vueEventBus.js";

export default {
    props: {
        buildingtypes: {
            default: {}
        },
        locale: {
            default: "nl"
        },
        translations: null,
        region: null
    },
    data() {
        return {
            isSelectable: false,
            alternativeBuildingTypes: null,
            selectedRegion: this.region
        };
    },
    methods: {
        redirect(slug) {
            window.location =
                "/" + this.locale + "/" + this.selectedRegion.slug + "/" + slug;
        },
        getAlternativeBuildingTypes() {
            this.alternativeBuildingTypes = this.buildingtypes.filter(i => {
                return !this.selectedRegion.buildingtypes.some(bt => {
                    return i.slug === bt.slug;
                });
            });
        }
    },
    mounted() {},
    created() {
        this.isSelectable = false;
        this.getAlternativeBuildingTypes();
        EventBus.$on("region-selected", data => {
            // check for 'landelijk'
            if (data && data.id === null) {
                data.buildingtypes = this.buildingtypes;
            }

            // set received data
            this.selectedRegion = data;
            if (this.selectedRegion) {
                this.isSelectable = true;

                // filter alternatives
                this.getAlternativeBuildingTypes();
                return;
            }
        });
    }
};
</script>
