<template>
    <div>
        <div v-if="!buildingTypeAvailableForRegion" class="region-content">
            <p>{{ currentBuildingType.plural[0].toUpperCase() + currentBuildingType.plural.slice(1) }}
                {{ translations.not_available }} {{ region.name }}
            </p>

            <buildingtypes-component
                    :translations="translations"
                    :locale="locale"
                    :buildingtypes="buildingtypes"
                    :region="region"
            ></buildingtypes-component>
        </div>
        <div v-else class="region-btn">
            <p><a class="button is-primary-light" @click="redirect">{{ translations.select }}</a></p>
        </div>
    </div>
</template>

<script>
    // import eventBus so we can 'communicate' with other components
    import { EventBus } from '../vueEventBus.js';
    import BuildingtypesComponent from './buildingtypesForModalComponent.vue';

    export default {
        components: {BuildingtypesComponent},
        props: {
            translations: {
              default: null
            },
            buildingtypes: {
                default: {},
            },
            locale: {
                default: 'nl'
            },
            currentBuildingType: {
                default: null
            }
        },
        data() {
            return {
                isSelectable: false,
                region: {},
                filteredBuildingTypes: {},
                buildingTypeAvailableForRegion: true
            }
        },
        methods: {
            redirect() {
                window.location = '/' + this.locale + '/' + this.region.slug + '/' + this.currentBuildingType.slug
            },
            isCurrentBuildingTypeAvailableForNewRegion() {
                return this.region.buildingtypes.some(i => {
                     return i.slug===this.currentBuildingType.slug
                })
            }
        },
        created (){
            EventBus.$on('region-selected', (data) => {
                // check for 'landelijk'
                if (data && data.id=== null) {
                    data.buildingtypes = this.buildingtypes
                }

                // set received data
                this.region = data;

                this.buildingTypeAvailableForRegion = this.isCurrentBuildingTypeAvailableForNewRegion();

                if (this.region) {
                    this.isSelectable = true;
                    return;
                }

                this.isSelectable = false;
            })
        },
        mounted() {
        }
    }
</script>
