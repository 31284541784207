<template>
    <ul
        v-if="mainCategories && mainCategories.length"
        class="sidebar-menu-sub"
        id="measure-main-cat-nav-items"
    >
        <!-- MainCategory -->
        <li
            v-for="mainCategory in mainCategories"
            :class="getMainCategoryClass(mainCategory)"
        >
            <span
                class="anchor-style"
                v-if="
                    isMobileNavigation &&
                        active.mainCategory != mainCategory.slug
                "
                @click="toggleActiveMainCategory(mainCategory)"
            >
                <span :style="getBackgroundColorStyle(mainCategory)"
                    ><i class="icofont-thin-right"></i
                ></span>
                {{ mainCategory.title }}
            </span>
            <a
                v-else
                @click="toggleActiveMainCategory(mainCategory)"
                :href="
                    isMobileNavigation &&
                    active.mainCategory != mainCategory.slug
                        ? 'javascript:void(0)'
                        : '/' +
                          locale +
                          '/' +
                          activeRegion.slug +
                          '/' +
                          activeBuildingType.slug +
                          '#' +
                          mainCategory.slug
                "
            >
                <span :style="getBackgroundColorStyle(mainCategory)"
                    ><i class="icofont-thin-right"></i
                ></span>
                {{ mainCategory.title }}
            </a>
            <ul
                v-if="
                    mainCategory['sub-categories'] &&
                        mainCategory['sub-categories'].length
                "
                class="sidebar-menu-sub-sub"
            >
                <!-- SubCategory -->

                <li
                    v-for="subCategory in mainCategory['sub-categories']"
                    :class="getSubCategoryClass(subCategory)"
                >
                    <span
                        :style="getColorStyle(mainCategory)"
                        @click="
                            redirectTo(
                                '/' + mainCategory.slug + '/' + subCategory.slug
                            )
                        "
                        ><i class="icofont-thin-right"></i
                    ></span>
                    <a
                        @click="
                            redirectTo(
                                '/' + mainCategory.slug + '/' + subCategory.slug
                            )
                        "
                        >{{ subCategory.title }}</a
                    >
                    <!-- Measure -->
                    <ul
                        v-if="
                            subCategory.measures && subCategory.measures.length
                        "
                        class="currentpage-menu"
                    >
                        <li
                            v-for="measure in subCategory.measures"
                            class="currentpage-menu-item"
                        >
                            <span :style="getColorStyle(mainCategory)"
                                ><i class="icofont-ui-press"></i
                            ></span>
                            <a :href="'#' + measure.slug">{{
                                measure.title
                            }}</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </li>
    </ul>
</template>

<script>
// import eventBus so we can 'communicate' with other components
import { EventBus } from "../../vueEventBus.js";
import Page from "../../../models/page.js";

export default {
    props: {
        color: {
            default: "#f00"
        },
        mainCategories: {
            required: true
        },
        activeRegion: {
            required: true
        },
        activeBuildingType: {
            required: true
        },
        locale: {
            default: "nl"
        },
        activeMeasureMainCategory: {
            default: null
        },
        activeMeasureSubCategory: {
            default: null
        },
        isMobileNavigation: {
            default: false
        }
    },
    data() {
        return {
            active: new Page()
        };
    },
    computed: {},
    methods: {
        getBackgroundColorStyle(mainCategory) {
            return "background-color:" + mainCategory.color;
        },
        getColorStyle(mainCategory) {
            return "color:" + mainCategory.color;
        },
        toggleActiveMainCategory(mainCategory) {
            if (
                this.isMobileNavigation &&
                this.active.mainCategory == mainCategory.slug
            ) {
                this.collapseMobileNavigation();
            }

            if (this.activeMeasureMainCategory !== null) {
                if (!this.isMobileNavigation) {
                    this.redirectTo("#" + mainCategory.slug);
                } else {
                    this.active.mainCategory = mainCategory.slug;
                }
            } else {
                this.active.mainCategory = mainCategory.slug;
            }

            this.navigateTo(this.active);
        },
        getMainCategoryClass(mainCategory) {
            if (this.active.mainCategory === mainCategory.slug) {
                return "sidebar-menu-sub-item active " + mainCategory.slug;
            }
            return "sidebar-menu-sub-item " + mainCategory.slug;
        },
        getSubCategoryClass(subCategory) {
            if (this.active.subCategory === subCategory.slug) {
                return "sidebar-menu-sub-sub-item active";
            }
            return "sidebar-menu-sub-sub-item";
        },
        navigateTo(page) {
            EventBus.$emit("navigateTo", page);
        },
        redirectTo(path) {
            document.location =
                "/" +
                this.locale +
                "/" +
                this.activeRegion.slug +
                "/" +
                this.activeBuildingType.slug +
                path;
        },
        collapseMobileNavigation() {
            var burger = document.querySelector(".burger");
            var menu = document.querySelector("#" + burger.dataset.target);
            burger.classList.toggle("is-active");
            menu.classList.toggle("is-active");
        }
    },
    mounted() {
        // check if we're not on the root page (means we're entering after a new request)
        if (this.activeMeasureMainCategory !== null) {
            this.active.mainCategory = this.activeMeasureMainCategory.slug;
        } else {
            // check for anchor tag in url
            let uri = window.location.href.split("#");
            if (uri.length == 2) {
                this.active.mainCategory = uri[1];
            }
        }
        // set state upon new request
        if (localStorage.getItem("activeMeasureMainCategory")) {
            this.navigateTo(this.active);
        }
    }
};
</script>
