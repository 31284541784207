function setCurrentPage() {
    var previousPages = JSON.parse(localStorage.getItem("previousPages"));
    
    if (previousPages) {
        previousPages.unshift(location.href);
        previousPages = previousPages.slice(0, 2);
        localStorage.setItem("previousPages", JSON.stringify(previousPages));
    } else {
        localStorage.setItem("previousPages", JSON.stringify([location.href]));
    }
}

setCurrentPage();
