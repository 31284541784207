<template>
    <a
        v-if="getSavedMeasuresLength > 0"
        href="../download"
        class="saved-measure-download button is-primary-light has-arrow"
        target="_blank"
        >{{ translations }}</a
    >
</template>

<script>
export default {
    props: {
        translations: {
            default: null,
            type: String
        }
    },
    computed: {
        getSavedMeasuresLength() {
            return this.$store.getters.savedMeasuresLength;
        }
    }
};
</script>
