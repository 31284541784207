// Tooltip save
let save = document.getElementById("header-save");
if (save) {
    save.addEventListener("mouseover", function(e) {
        // Add class hover on mouseover
        this.getElementsByClassName('header-tooltip')[0].classList.add("hover");
    }, false);

    save.addEventListener('mouseleave', e => {
        // Remove class hover after mouseleave
        setTimeout(function() {
            document.getElementById("header-save").getElementsByClassName('header-tooltip')[0].classList.remove("hover");
        }, 500);
    });
}

// Tooltip sharer
let addthis = document.getElementById("header-share");

if (addthis) {
    addthis.addEventListener("mouseover", function(e) {
        // Add class hover on mouseover
        this.getElementsByClassName('header-tooltip')[0].classList.add("hover");
    }, false);

    addthis.addEventListener('mouseleave', e => {
        // Remove class hover after mouseleave
        setTimeout(function() {
            document.getElementById("header-share").getElementsByClassName('header-tooltip')[0].classList.remove("hover");
        }, 500);
    });
}
