<template>
    <div>
        <nav class="navbar filterbar" role="filter" aria-label="dropdown filter">
            <div class="navbar-start">
                <div class="navbar-item">
                    <div class="select">
                        <select v-model="selectedMeasure">
                            <option :value="0">{{ translations['select-first-option'] }}</option>
                            <option v-for="item in measures" :value="item.id">{{ item.title }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </nav>

        <div v-for="item in financeItems" class="finance-wrapper">
            <div :id="'financing-' + item.id" class="finance-wrapper">
                <div class="finance-title" :ref="'financing' + item.id">
                    <h2>{{ item.title }}</h2>
                    <p>{{ translations['by']}}: {{ item.who }}</p>
                </div>

                <div class="finance-content">
                    <div class="columns">
                        <div class="column is-2">
                            <div v-if="item.authorityImgUrl" class="finance-logo">
                                <img :src="item.authorityImgUrl" alt="" />
                            </div>
                        </div>

                        <div class="column is-5">
                            <h3>{{ translations.description }}</h3>
                            <div v-html="item.description"></div>

                            <h3>{{ translations.amount }}</h3>
                            <div v-html="item.amount"></div>

                            <div v-for="button in item.buttons">
                                <a :href="button.url" class="button is-primary-light has-arrow">{{ button.name }}</a>
                            </div>
                        </div>

                        <div class="column is-5">
                            <h3>{{ translations.conditions }}</h3>
                            <div v-html="item.conditions"></div>

<!--                            <template v-if="item.buildingStates.length > 0">-->
<!--                                <br/>-->
<!--                                <h3>{{ translations.buildingstates }}</h3>-->
<!--                                <ul>-->
<!--                                    <li-->
<!--                                        v-for="(buildingstate, index) in item.buildingStates"-->
<!--                                        :key="index"-->
<!--                                    >-->
<!--                                        {{ translations['buildingTypes'][buildingstate] }}-->
<!--                                    </li>-->
<!--                                </ul>-->
<!--                            </template>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            measures: {
                required: true
            },
            financings: {
                required: true
            },
            translations: {
                required: true
            },
        },
        data() {
            return {
                financeItems: {},
                selectedMeasure: 0,
            }
        },
        watch: {
            selectedMeasure: function(value) {
                localStorage.setItem('dgm.filter.measure', value)

                if (value === 0) {
                    this.reset();
                } else {
                    this.filter()
                }
            }
        },
        methods: {
            filter: function() {
                /* filtering by looping through all the financings */
                this.financeItems = this.financings.filter(el => {
                    if (el.hasOwnProperty('measures')) {
                        return el.measures.some(i => {
                            return i.id===this.selectedMeasure;
                        })
                    }
                    return false
                });
            },
            getLocalStorageValue: function(key) {
                if (localStorage.getItem(key)) {
                    return localStorage.getItem(key)
                }
                return null
            },
            reset: function() {
                this.financeItems = this.financings
            },
            scrollTo: function(financingId) {
                const vm = this
                setTimeout(function(){
                    var element = vm.$refs[financingId]
                    var top = element[0].offsetTop;
                    window.scrollTo(0, top);
                },100);
            }
        },
        mounted() {
            /* Copy props to data, since we should never change props */
            this.financeItems = this.financings

            // make scrolling to item possible. We need all the items!
            let uri = window.location.href.split('#');
            if (uri.length == 2) {
                this.scrollTo(uri[1]);
            } else {
                /* if we used a filter before, the last knows value was stored. Get it, set it */
                this.selectedMeasure = Number(this.getLocalStorageValue('dgm.filter.measure'));
            }
        }
    }
</script>
