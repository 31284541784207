<template>
    <div>
        <span v-if="getSavedMeasuresLength > 0" class="header-counter">{{
            getSavedMeasuresLength
        }}</span>
    </div>
</template>

<script>
export default {
    props: {},
    data() {
        return {};
    },
    computed: {
        getSavedMeasuresLength() {
            return this.$store.getters.savedMeasuresLength;
            // return _.size(this.$store.state.savedMeasures);
        }
    },
    methods: {}
};
</script>
