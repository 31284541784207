<template>
    <div
        class="calculator-results columns is-gapless"
        :style="{ 'background-color': subColor }"
    >
        <div
            v-if="!Number.isNaN(results.yearlySavings.result_low) && !Number.isNaN(results.yearlySavings.result_high)"
            class="column"
            :style="
                highlightResult === 'yearlysavings'
                    ? { background: mainColorRgba }
                    : {}
            "
        >
            <div class="calculator-results-content">
                <p
                    class="has-text-centered"
                    v-html="stringTranslations.yearlySavingsLabel"
                ></p>
                <p class="has-text-centered">
                    <b
                        >€{{ formatNumber(results.yearlySavings.result_low) }} -
                        €{{
                            formatNumber(results.yearlySavings.result_high)
                        }}</b
                    >
                </p>
            </div>
            <div class="calculator-results-triangle"></div>
        </div>

        <div
            v-if="!Number.isNaN(results.investment.result_low) && !Number.isNaN(results.investment.result_high)"
            class="column"
            :style="
                highlightResult === 'costs' ? { background: mainColorRgba } : {}
            "
        >
            <div class="calculator-results-content">
                <tippy placement="top" theme="light" :arrow="true">
                    <template  #default>
                        <p class="has-text-centered">
                            <u v-html="stringTranslations.investmentLabel"></u>
                        </p>
                    </template>
                    <template #content>
                        <div id="investmentTooltipContent" class="tooltip-content">
                            <h3
                                class="tooltip-header"
                                v-html="stringTranslations.investmentTooltipTitle"
                            ></h3>
                            <p
                                v-html="
                                    stringTranslations.investmentTooltipDescription
                                "
                            ></p>
                        </div>
                    </template>
                </tippy>

                <p class="has-text-centered">
                    <b
                        >€{{ formatNumber(results.investment.result_low) }} -
                        €{{ formatNumber(results.investment.result_high) }}</b
                    >
                </p>
            </div>
        </div>

        <div
            class="column"
            v-if="parseInt(results.comfort) > 0"
            :style="
                highlightResult === 'comfort'
                    ? { background: mainColorRgba }
                    : {}
            "
        >
            <div class="calculator-results-content">
                <p
                    class="has-text-centered"
                    v-html="stringTranslations.extraComfortLabel"
                ></p>
                <p class="has-text-centered">
                    <b>
                        <span
                            v-for="star in 5"
                            :key="star"
                        ><i class="icofont-star" :class="{'disabled': star > parseInt(results.comfort)}"></i></span>
                    </b>
                </p>
            </div>
        </div>

        <div
            v-if="!Number.isNaN(results.co2Savings.result_high)"
            class="column"
            :style="
                highlightResult === 'greenscore'
                    ? { background: mainColorRgba }
                    : {}
            "
        >
            <div class="calculator-results-content">
                <tippy
                    v-if="co2Comparisons"
                    placement="top"
                    theme="light"
                    :arrow="true"
                >
                    <template #default>
                        <p class="has-text-centered">
                            <u v-html="stringTranslations.savedLabel"></u>
                        </p>
                    </template>

                    <template #content>
                        <div id="co2SavingsTooltipContent" class="tooltip-content">
                            <h3
                                class="tooltip-header"
                                v-html="stringTranslations.savedTooltipTitle"
                            ></h3>
                            <p>
                                <b v-html="parseYearlySavingsComparison"></b>
                            </p>

                            <div
                                class="line-with-image-wrapper"
                                v-if="co2Comparisons.includes('flightToRome')"
                            >
                                <img :src="assetPaths.plane" />
                                <p>
                                    {{
                                        Math.round(
                                            results.co2Savings.result_high /
                                                variables["flight_to_rome"].value
                                        )
                                    }}
                                    &nbsp;
                                    <span
                                        v-html="
                                            stringTranslations.savedTooltipDescriptionAmsterdamRome.replace(
                                                '%s',
                                                ''
                                            )
                                        "
                                    ></span>
                                </p>
                            </div>

                            <div
                                class="line-with-image-wrapper"
                                v-if="co2Comparisons.includes('driveToParis')"
                            >
                                <img :src="assetPaths.car" />
                                <p>
                                    {{
                                        Math.round(
                                            results.co2Savings.result_high /
                                                variables["drive_to_paris"].value
                                        )
                                    }}
                                    &nbsp;
                                    <span
                                        v-html="
                                            stringTranslations.savedTooltipDescriptionMaastrichtParijs.replace(
                                                '%s',
                                                ''
                                            )
                                        "
                                    ></span>
                                </p>
                            </div>
                            <div
                                class="line-with-image-wrapper"
                                v-if="co2Comparisons.includes('60WattBulb')"
                            >
                                <img :src="assetPaths.bulb" />
                                <p>
                                    {{
                                        Math.round(
                                            results.co2Savings.result_high /
                                                variables["60_watt_bulb"].value
                                        )
                                    }}
                                    &nbsp;
                                    <span
                                        v-html="
                                            stringTranslations.savedTooltipDescription60WattBulb.replace(
                                                '%s',
                                                ''
                                            )
                                        "
                                    ></span>
                                </p>
                            </div>
                            <div
                                class="line-with-image-wrapper"
                                v-if="co2Comparisons.includes('trees')"
                            >
                                <img :src="assetPaths.tree" />
                                <p>
                                    {{
                                        Math.round(
                                            results.co2Savings.result_high /
                                                variables["co2_per_tree"].value
                                        )
                                    }}
                                    &nbsp;
                                    <span
                                        v-html="
                                            stringTranslations.savedTooltipDescriptionTrees.replace(
                                                '%s',
                                                ''
                                            )
                                        "
                                    ></span>
                                </p>
                            </div>
                        </div>
                    </template>
                </tippy>

                <p
                    v-else
                    class="has-text-centered"
                    v-html="stringTranslations.savedLabel"
                ></p>

                <p class="has-text-centered">
                    <b
                        >{{ formatNumber(results.co2Savings.result_high)
                        }}<span v-html="stringTranslations.kgsPerYear"></span>
                    </b>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        mainColor: {
            type: String,
            default: "#000000"
        },
        co2Comparisons: {
            type: Array,
            default: []
        },

        stringTranslations: {
            type: Object,
            default: {}
        },
        results: {
            type: Object,
            default: {}
        },
        subColor: {
            type: String,
            default: "#FFFFFF"
        },
        assetPaths: {
            type: Object,
            default: {}
        },
        highlightResult: {
            type: String,
            default: ""
        },
        variables: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            investmentTooltip: null
        };
    },
    watch: {},
    methods: {
        formatNumber: function(num) {
            return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
        }
    },
    computed: {
        mainColorRgba: function() {
            const hex2rgba = (hex, alpha = 1) => {
                const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
                return `rgba(${r},${g},${b},0.25)`;
            };
            return hex2rgba(this.mainColor);
        },
        parseYearlySavingsComparison: function() {
            if (
                typeof this.stringTranslations.yearlySavingsComparison !=
                "undefined"
            ) {
                const splitYearlySavingsComparison = this.stringTranslations.yearlySavingsComparison.split(
                    "%s"
                );
                return (
                    splitYearlySavingsComparison[0] +
                    this.results.co2Savings.result_high +
                    splitYearlySavingsComparison[1]
                );
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            // var investmentTooltipContent = this.$el.querySelector(
            //     "#investmentTooltipContent"
            // );
            // var investmentTooltip =  tippy("#investmentTooltip", {
            //     theme: "light",
            //     content: investmentTooltipContent.innerHTML,
            //     allowHTML: true,
            //     interactive: true
            // });
            // var co2SavingsTooltipContent = this.$el.querySelector(
            //     "#co2SavingsTooltipContent"
            // );
            // var co2SavingsTooltip = tippy("#co2SavingsTooltip", {
            //     theme: "light",
            //     content: co2SavingsTooltipContent.innerHTML,
            //     allowHTML: true,
            //     interactive: true
            // });
        });
    }
};
</script>
