window.addEventListener('load', function () {
    /* Collapsible blocks on measure page */
    var coll = document.getElementsByClassName("toggle-button");
    var i;

    for (i = 0; i < coll.length; i++) {
        coll[i].addEventListener("click", function() {
            this.classList.toggle("active");
            var content = this.nextElementSibling;

            if (content.style.display === "block") {
                content.style.display = "none";
            } else {
                content.style.display = "block";
            }
        });
    }

    /* Show content on measure block mobile  */
    var toggleButton = document.getElementsByClassName("toggle-more-button");
    var i;

    for (i = 0; i < toggleButton.length; i++) {
        toggleButton[i].addEventListener("click", function() {
            this.classList.toggle("active");
            var content = this.parentNode.previousElementSibling;

            if (content.classList.contains("mobile-readmore")) {
                content.classList.remove("mobile-readmore");
            } else {
                content.classList.add("mobile-readmore");
            }
        });
    }
});
