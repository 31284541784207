// The following code is based off a toggle menu by @Bradcomp
// source: https://gist.github.com/Bradcomp/a9ef2ef322a8e8017443b626208999c1
(function() {
    /* Responsive dropdown menu */
    var burger = document.querySelector(".burger");

    if (burger) {
        var menu = document.querySelector("#" + burger.dataset.target);
        var top = document.querySelector(".hero-head");
        var content = document.querySelector(".hero-content");
        var footer = document.querySelector("footer");

        burger.addEventListener("click", function() {
            burger.classList.toggle("is-active");
            menu.classList.toggle("is-active");
            top.classList.toggle("menu-open");
            content.classList.toggle("menu-open");
            footer.classList.toggle("menu-open");
        });
    }
})();
