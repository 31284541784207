<template>
    <div class="region-field field">
        <div class="columns">
            <div class="column is-10">
                <ul
                    class="typologies-title has-text-centered"
                >
                    <li>
                        <strong>
                            <template v-if="!regionNotFound">
                                {{ translations["choose-buildingtype"] }}
                                <template v-if="region.id != null">
                                    {{
                                        translations[
                                            "choose-buildingtype-preposition"
                                            ]
                                    }}
                                    {{ region.name }}
                                </template>
                            </template>
                            <template v-else>
                                {{ regionNotFoundTranslation }}
                            </template>
                        </strong>
                    </li>
                </ul>
                <!--
                                <ul v-if="!isSelectable" class="typologies">
                                    <li class="typologies-items" v-for="bt in buildingtypes">
                                        <div
                                            :class="
                                                `typologies-item typologies-item-${bt.singular}`
                                            "
                                        >
                                            <img v-if="bt.backimg" :src="bt.backimg" />
                                            <img
                                                v-if="bt.frontimg"
                                                :src="bt.frontimg"
                                                :alt="bt.singular"
                                            />
                                        </div>
                                    </li>
                                </ul> -->
                <div>
                    <div class="building-swiper-wrapper">
                        <div class="building-swiper-container swiper swiper-container">
                            <ul class="typologies property-type swiper-wrapper">
                                <li
                                    class="typologies-items swiper-slide"
                                    v-for="fbt in region.buildingtypes"
                                    :key="fbt.slug"
                                >
                                    <a
                                        :href="`/${locale}/${region.slug}/${fbt.slug}`"
                                        @click="redirect(fbt.slug)"
                                        :class="
                                            `typologies-item typologies-item-${fbt.singular}`
                                        "
                                    >
                                        <span class="typologies-label">{{
                                                fbt.singular
                                            }}</span>
                                        <img v-if="fbt.backimg" :src="fbt.backimg" />
                                        <img
                                            v-if="fbt.frontimg"
                                            :src="fbt.frontimg"
                                            :alt="fbt.singular"
                                        />
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div class="swiper-button-next  building-swiper-button-next"></div>
                        <div class="swiper-button-prev  building-swiper-button-prev"></div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="nationWideBuildingTypes.length > 0" class="columns nationwide-buildingtypes">
            <div class="column is-10">
                <ul
                    class="typologies-title has-text-centered"
                >
                    <li>
                        <strong>
                            {{ translations["choose-nationwide-buildingtype"] }}
                        </strong>
                    </li>
                </ul>

                <div class="building-nationwide-swiper-wrapper">
                    <div class="building-nationwide-swiper-container swiper swiper-container">
                        <ul class="typologies typologies__nationwide property-type swiper-wrapper">
                            <li
                                class="typologies-items swiper-slide"
                                v-for="fbt in nationWideBuildingTypes"
                                :key="fbt.slug"
                            >
                                <a
                                    :href="`/${locale}/landelijk/${fbt.slug}`"
                                    @click="redirect(fbt.slug)"
                                    :class="
                                        `typologies-item typologies-item-${fbt.singular}`
                                    "
                                >
                                    <span class="typologies-label">{{
                                            fbt.singular
                                        }}</span>
                                    <img v-if="fbt.backimg" :src="fbt.backimg" />
                                    <img
                                        v-if="fbt.frontimg"
                                        :src="fbt.frontimg"
                                        :alt="fbt.singular"
                                    />
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div class="swiper-button-next  building-nationwide-swiper-button-next"></div>
                    <div class="swiper-button-prev  building-nationwide-swiper-button-prev"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import eventBus so we can 'communicate' with other components
import { EventBus } from "../../vueEventBus.js";
// Import Swiper Vue.js components
import { Swiper } from 'swiper/bundle';

export default {
    props: {
        buildingtypes: {
            default: {}
        },
        locale: {
            default: "nl"
        },
        translations: {
            required: true
        }
    },
    data() {
        return {
            isSelectable: false,
            region: {},
            filteredBuildingTypes: {},
            regionNotFound: false,
            regionSearch: '',
        };
    },
    computed: {
        nationWideBuildingTypes() {
            return this.region && this.region.buildingtypes
                ? this.buildingtypes.filter(bt => {
                    return this.region.buildingtypes.filter(rbt => rbt.slug === bt.slug).length === 0
                })
                : [];
        },
        regionNotFoundTranslation() {
            return this.translations["region-not-found"].replace('{{search}}', this.regionSearch);
        }
    },
    mounted() {
        this.initSwiper()
    },
    methods: {
        hasEmptyRegion(obj){
            return Object.keys(obj).length === 0;
        },
        redirect(slug) {
            window.location =
                "/" + this.locale + "/" + this.region.slug + "/" + slug;
        },
        initSwiper() {
            const building = new Swiper('.building-swiper-container', {
                slidesPerView: 1,
                spaceBetween: 12,
                centerInsufficientSlides: false,
                allowTouchMove: false,
                watchOverflow: true,
                navigation: {
                    nextEl: '.building-swiper-button-next',
                    prevEl: '.building-swiper-button-prev',
                    disabledClass: 'disabled_swiper_button'
                },
                breakpoints: {
                    525: {
                        slidesPerView: 2,
                        allowTouchMove: true,
                    },
                    769: {
                        slidesPerView: 3,
                        allowTouchMove: true,
                    },
                    1024: {
                        slidesPerView: 6,
                    },
                }
            });
            const buildingNationwide = new Swiper('.building-nationwide-swiper-container', {
                slidesPerView: 1,
                spaceBetween: 10,
                centerInsufficientSlides: true,
                loop: true,
                navigation: {
                    nextEl: '.building-nationwide-swiper-button-next',
                    prevEl: '.building-nationwide-swiper-button-prev',
                    disabledClass: 'disabled_swiper_button'
                },
                breakpoints: {
                    525: {
                        slidesPerView: 2,
                    },
                    769: {
                        slidesPerView: 3,
                    },
                    1024: {
                        slidesPerView: 6,
                    },
                }
            });
        }
    },
    created() {
        EventBus.$on("region-selected", data => {
            this.regionNotFound = false;
            // check for 'landelijk'
            if (data && data.id === null) {
                data.buildingtypes = this.buildingtypes;
            }
            // set received data
            this.region = data;
            if (this.region) {
                this.isSelectable = true;
                return;
            }
            this.isSelectable = false;
        });
        EventBus.$on("region-not-found", data => {
            this.regionNotFound = true;
            this.regionSearch = data;
        });
    },
    watch: {
        region: function() {
            this.$nextTick(() => {
                this.initSwiper()
            })
        },
        isSelectable: function() {
            this.$nextTick(() => {
                this.initSwiper()
            })
        }
    }
};
</script>
