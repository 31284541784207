<template>
    <div class="calculator-instance" v-if="calculatorData != undefined">
        <div
            v-for="(input, index) in calculatorData.parameters"
            :key="input.id"
        >
            <calculator-number-component
                v-if="input.type === 'number'"
                :inputIndex="index"
                :inputValue="{}"
                :mainColor="mainColor"
                :subColor="subColor"
                :item-data="input"
                v-on:change="catchValueChange"
            ></calculator-number-component>
            <calculator-range-component
                v-if="input.type === 'slider'"
                :inputIndex="index"
                :inputValue="{}"
                :mainColor="mainColor"
                :subColor="subColor"
                :item-data="input"
                v-on:change="catchValueChange"
            ></calculator-range-component>
            <calculator-radio-component
                v-if="input.type === 'radio'"
                :inputIndex="index"
                :inputValue="{}"
                :mainColor="mainColor"
                :subColor="subColor"
                :item-data="input"
                v-on:change="catchFormulaChange"
            ></calculator-radio-component>
        </div>
        <calculator-results-component
            v-if="displayResults"
            :string-translations="translations"
            :co2-comparisons="calculatorData.showComparisons"
            :mainColor="mainColor"
            :subColor="subColor"
            :asset-paths="assetPaths"
            :highlight-result="calculatorData.highlightOutcome"
            :variables="calculatorData.variables"
            :results="{
                co2Savings: calculateResultRefined('co2Savings'),
                yearlySavings: calculateResultRefined('yearlySavings'),
                investment: calculateResultRefined('investment'),
                comfort: comfortLevel
            }"
        ></calculator-results-component>
    </div>
</template>

<script>
import calculatorRangeComponent from "./calculatorRangeComponent.vue";
import calculatorRadioComponent from "./calculatorRadioComponent.vue";
import calculatorNumberComponent from "./calculatorNumberComponent.vue";
import calculatorResultsComponent from "./calculatorResultsComponent.vue";

export default {
    components: {
        "calculator-range-component": calculatorRangeComponent,
        "calculator-radio-component": calculatorRadioComponent,
        "calculator-number-component": calculatorNumberComponent,
        "calculator-results-component": calculatorResultsComponent
    },
    props: {
        calculatorData: {
            type: Object,
            default: null
        },
        comfortInputLevel: {
            type: String,
            default: null
        },
        translations: {
            type: Object,
            default: null
        },
        measureId: {
            type: Number,
            default: null
        },
        measureRegion: {
            type: Number,
            default: null
        },
        measureBuildingtype: {
            type: Number,
            default: null
        },
        mainColor: {
            type: String,
            default: "#000000"
        },
        subColor: {
            type: String,
            default: "#FFFFFF"
        },
        assetPaths: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            liveCalculatorData: {},
            comfortLevel: null,
            parameterInputValues: [],
            displayResults: false
        };
    },
    mounted() {
        // ON mount build a live data set we can work in vue with.
        // This is reactive versus the incoming data

        // SET COMFORT LEVEL
        this.comfortLevel = this.comfortInputLevel;

        if (this.calculatorData != null) {
            let inputItems = this.calculatorData.parameters;
            const buildLiveCalculatorData = {};
            for (let inputItem of inputItems) {
                buildLiveCalculatorData[inputItem.variableName] = {
                    formula:
                        // if radio button add it's formula
                        inputItem.type === "radio"
                            ? inputItem.radioitems[
                                  Object.keys(inputItem.radioitems)[0]
                              ]
                            : null,
                    value: inputItem.default ? inputItem.default : null
                };

                // IF RADIO INPUT (FORMULA CHANGE) OVERWRITE COMFORT LEVEL
                if (inputItem.type === "radio") {
                    this.comfortLevel =
                        inputItem.radioitems[
                            (this.comfortLevel = Object.keys(
                                inputItem.radioitems
                            )[0])
                        ].comfort;
                }
            }

            this.liveCalculatorData = buildLiveCalculatorData;
            this.displayResults = true;
        }

        this.prepareSaveMeasures();
    },
    created() {
        var buildParameterInputValues = [];
        let inputItems = this.calculatorData.parameters;

        for (let inputItem of inputItems) {
            buildParameterInputValues.push({});
        }

        this.parameterInputValues = buildParameterInputValues;
    },
    computed: {
        getPreparedSaveMeasures() {
            return this.$store.state.prepareSaveMeasures;
        },
        getSavedMeasuresThis() {
            if (typeof this.$store.state.savedMeasures != "undefined") {
                return this.$store.state.savedMeasures[this.measureId];
            }
        },
        getSavedMeasuresThisInputValues() {
            if (typeof this.$store.state.savedMeasures != "undefined") {
                if (
                    typeof this.$store.state.savedMeasures[this.measureId]
                        .calculations != "undefined"
                ) {
                    return this.$store.state.savedMeasures[this.measureId]
                        .calculations.inputValues;
                }
            }
        }
    },
    methods: {
        prepareSaveMeasures: function(valueChange) {
            this.$store.dispatch("prepareSaveMeasures", {
                id: this.measureId,
                content: {
                    calculations: {
                        yearlySavingsMin: this.getRefinedResultLow(
                            "yearlySavings"
                        ),
                        yearlySavingsMax: this.getRefinedResultHigh(
                            "yearlySavings"
                        ),
                        investmentMin: this.getRefinedResultLow("investment"),
                        investmentMax: this.getRefinedResultHigh("investment"),
                        comfort: this.comfortLevel,
                        co2savings: this.getRefinedResultHigh("co2Savings"),
                        inputValues: []
                    }
                }
            });
        },
        catchValueChange: function(input) {
            if (this.liveCalculatorData[input.variableName]) {
                this.liveCalculatorData[input.variableName]['value'] = input.value;
            }

            // SET PREPARE SAVE MEASURES ON VALUE CHANGE (when you change the input this will influence the set cookie)
            this.prepareSaveMeasures(input.value);
        },
        catchFormulaChange: function(input) {
            if (this.liveCalculatorData[input.variableName]) {
                this.liveCalculatorData[input.variableName]['formula'] = input.formula;
            }

            this.comfortLevel = input.formula.comfort;

            // SET PREPARE SAVE MEASURES ON VALUE CHANGE (when you change the input this will influence the set cookie)
            this.prepareSaveMeasures();
        },
        getRefinedResultLow(savingsType) {
            return this.calculateResultRefined(savingsType).result_low;
        },
        getRefinedResultHigh(savingsType) {
            return this.calculateResultRefined(savingsType).result_high;
        },
        calculateResultRefined: function(savingsType) {
            const roundnumber = this.calculatorData[savingsType].round;

            const deviation =
                typeof this.calculatorData[savingsType].deviation != "undefined"
                    ? this.calculatorData[savingsType].deviation
                    : 0;

            const deviationIntervals = {
                low:
                    this.calculateResultRaw(savingsType) -
                    (this.calculateResultRaw(savingsType) / 100) * deviation,
                high:
                    this.calculateResultRaw(savingsType) +
                    (this.calculateResultRaw(savingsType) / 100) * deviation
            };

            const roundedDeviationIntervals = {
                result_low:
                    Math.round(deviationIntervals.low / roundnumber) *
                    roundnumber,
                result_high:
                    Math.round(deviationIntervals.high / roundnumber) *
                    roundnumber
            };

            return roundedDeviationIntervals;
        },

        calculateResultRaw: function(savingsType) {
            // determain if formula references to other formula: find "_formula"
            // if so then find the reference formulas and compile them in to a string: compiledFormula
            // if not use existing formul
            if (this.calculatorData[savingsType].formula.includes("_formula")) {
                var re = /{([^}]+)}/g;
                var formulaArray = this.calculatorData[savingsType].formula
                    .split(re)
                    .filter(Boolean);

                var compiledFormula = "";

                // loop trough the formla array and identify reference formulas
                for (const formulaElement of formulaArray) {
                    if (formulaElement.includes("_formula")) {
                        var parseReferenceVariableName = formulaElement
                            .split("_formula")[0]
                            .split("{")
                            .join("");

                        var parseReferenceFormulaName = formulaElement
                            .split("_formula")[1]
                            .split("_")
                            .join("");

                        var referenceFormula = this.liveCalculatorData[
                            parseReferenceVariableName
                        ].formula[
                            "formula_" + parseReferenceFormulaName.toLowerCase()
                        ];

                        // // RETURN VALUE from live data and and execute the formula (parsedFormula[1])
                        // // Build formula
                        compiledFormula = compiledFormula + referenceFormula;
                    } else {
                        if (/^[_a-zA-Z]+$/.test(formulaElement)) {
                            compiledFormula =
                                compiledFormula + "{" + formulaElement + "}";
                        } else {
                            compiledFormula = compiledFormula + formulaElement;
                        }
                    }
                }

                // split compiled formula into an array format
                var re = /{([^}]+)}/g;
                var arrayCompiledFormula = compiledFormula
                    .split(re)
                    .filter(Boolean);

                // Build and execute compiled formula
                return this.buildAndExecuteFormula(arrayCompiledFormula);
            } else {
                var re = /{([^}]+)}/g;
                var arrayParsedFormula = this.calculatorData[
                    savingsType
                ].formula
                    .split(re)
                    .filter(Boolean);

                // Build and execute compiled formul
                return this.buildAndExecuteFormula(arrayParsedFormula);
            }
        },

        buildAndExecuteFormula: function(parsedFormula) {
            var formulaLiveData = "";
            for (const formulaElement of parsedFormula) {
                // if variable
                if (/[a-z A-Z\\_\\"]+$/.test(formulaElement)) {
                    if (
                        typeof this.liveCalculatorData[formulaElement] !=
                        "undefined"
                    ) {
                        formulaLiveData =
                            formulaLiveData +
                            this.liveCalculatorData[formulaElement].value;
                    } else {
                        formulaLiveData = formulaLiveData + 1;
                    }
                } else {
                    formulaLiveData = formulaLiveData + formulaElement;
                }
            }

            var safeEvalReferencedFormula = new Function(
                "return " + formulaLiveData
            );

            return safeEvalReferencedFormula();
        }
    }
};
</script>
