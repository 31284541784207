import 'swiper/css/bundle';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/dist/svg-arrow.css';
import 'tippy.js/dist/border.css';
import 'tippy.js/themes/light.css';
import 'tippy.js/themes/light-border.css';
import '../sass/app-dgm.scss';

import { createApp } from 'vue';
import VueTippy from 'vue-tippy';
import App from './vue/app.js';
import SelectPlaceComponent from "./vue/shared/selectPlaceComponent.vue";
import NavigationComponent from "./vue/shared/navigationComponent.vue";
import SearchPlaceComponent from "./vue/shared/searchPlaceComponent.vue";

// import components for calculator
import calculatorComponent from "./vue/components/calculatorComponent/calculatorComponent.vue";
import calculatorHeaderCounterComponent from "./vue/components/calculatorComponent/calculatorHeaderCounterComponent.vue";
import calculatorSaveRemoveComponent from "./vue/components/calculatorComponent/calculatorSaveRemoveComponent.vue";

// import components for home
import BuildingtypesComponent from "./vue/components/home/buildingtypesComponent.vue";

// import components for measure
import MeasureMainCatNavItemComponent from "./vue/components/measure/measureMainCatNavItemComponent.vue";
import MeasureContentComponent from "./vue/components/measure/measureContentComponent.vue";
import FilterFinanceComponent from "./vue/components/filterFinancingComponent.vue";

// import download button for saved page
import savedPageDownloadComponent from "./vue/components/savedPage/savedPageDownloadComponent.vue";

// import filter component (version of toolbox filter component)
import FilterComponent from "./vue/components/exampleProjects/filterComponent.vue";

import "./components/menu";
import "./components/titleSlider";
import "./components/slider";
import "./components/collapsible";
import "./components/regionSelector";
import "./components/tooltip";
import "./components/cookie";
import "./components/previouspage";

import { store } from './vue/vueStore';

const vueAppFactory = () => {
    return createApp({ ...App })
        .use(store)
        .use(VueTippy, {
            flipDuration: 1000,
            defaultProps: {
                arrow: true,
                allowHTML: true,
            },
        })
        .component('calculator-save-remove-component', calculatorSaveRemoveComponent)
        .component('calculator-component', calculatorComponent);
}

window.initMap = function() {};
window.addEventListener("load", function() {
    let homeRegionSelectTarget = document.querySelector(
        "#dgm-home-region-select"
    );
    if (homeRegionSelectTarget) {
        const dgmHomeRegionSelect = vueAppFactory()
            .component('SelectPlaceComponent', SelectPlaceComponent)
            .mount('#dgm-home-region-select');
    }
    let homeBuildingTypeSelectTarget = document.querySelector(
        "#dgm-home-buildingtype-select"
    );
    if (homeBuildingTypeSelectTarget) {
        const dgmHomeRegionSelect = vueAppFactory()
            .component('BuildingtypesComponent', BuildingtypesComponent)
            .mount('#dgm-home-buildingtype-select');
    }

    let homeRegionSearchTarget = document.querySelector(
        "#dgm-home-region-search"
    );
    if (homeRegionSearchTarget) {
        const dgmHomeRegionSelected = vueAppFactory()
            .component('SearchPlaceComponent', SearchPlaceComponent)
            .mount('#dgm-home-region-search');
    }

    let navigationTargets = document.querySelectorAll("#navigation");
    for (let navigationTarget of navigationTargets) {
        vueAppFactory()
            .component('NavigationComponent', NavigationComponent)
            .component('MeasureMainCatNavItemComponent', MeasureMainCatNavItemComponent)
            .mount(navigationTarget);
    }

    let regionTarget = document.querySelector("#region");
    if (regionTarget) {
        const Region = vueAppFactory()
            .component('MeasureContentComponent', MeasureContentComponent)
            .mount('#region');
    }

    let financeFilterTarget = document.querySelector("#financeSelector");
    if (financeFilterTarget) {
        const FinanceFilter = vueAppFactory()
            .component('FilterFinanceComponent', FilterFinanceComponent)
            .mount('#financeSelector');
    }

    let calculatorHeaderCounterTargets = document.querySelectorAll(
        "#app-calculator-header-counter"
    );
    for (let calculatorHeaderCounterTarget of calculatorHeaderCounterTargets) {
        vueAppFactory()
            .component('calculatorHeaderCounterComponent', calculatorHeaderCounterComponent)
            .mount(calculatorHeaderCounterTarget);
    }

    let savedPageDownload = document.querySelector("#app-download-saved");
    if (savedPageDownload) {
        vueAppFactory()
            .component('savedPageDownloadComponent', savedPageDownloadComponent)
            .mount('#app-download-saved');
    }

    let filterComponentTarget = document.querySelector("#filterbar");
    if (filterComponentTarget) {
        const dgmHomeBuildingTypeSelect = vueAppFactory()
            .component('FilterComponent', FilterComponent)
            .mount('#filterbar');
    }
});

// Deploy calculator instance for each .app-calculator target

window.addEventListener("load", function() {
    let calculatorTargets = document.querySelectorAll(".app-calculator");
    for (let calculatorTarget of calculatorTargets) {
        vueAppFactory().mount(calculatorTarget);
    }
});
// Deploy calculator instance for each .app-calculator target

window.addEventListener("load", function() {
    let saveCalculatedTargets = document.querySelectorAll(
        ".app-save-calculated"
    );
    for (let saveCalculatedTarget of saveCalculatedTargets) {
        vueAppFactory().mount(saveCalculatedTarget);
    }
});
