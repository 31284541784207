import { createApp } from 'vue';
import App from './../vue/app.js';
import SelectPlaceComponent from "../vue/shared/selectPlaceComponent.vue";
import RedirectLandingPageComponent from "../vue/shared/redirectLandingPageComponent.vue";

const app = createApp(App);

/* Opens the region location modal or region type modal */
var location = document.querySelectorAll('.region-location-selector');
var type = document.querySelectorAll('.region-type-selector');
var modalRegion = document.querySelector('#modalRegion');
var modalRegionClose = document.querySelector('#modalRegion .delete');
var modalType = document.querySelector('#modalType');
var modalTypeClose = document.querySelector('#modalType .delete');

if (location) {
    location.forEach(function(el) {
        el.addEventListener('click', function() {
            document.querySelector("html").classList.add('no-scroll');
            modalRegion.classList.toggle('is-active');

            let regionSelectTarget = document.querySelector("#regionSelector");
            if (regionSelectTarget) {
                app.component('SelectPlaceComponent', SelectPlaceComponent);
                app.component('RedirectLandingPageComponent', RedirectLandingPageComponent);
                const RegionSelect = app.mount('#regionSelector');
            }

        });
    });

    if (modalRegionClose) {
        modalRegionClose.addEventListener('click', function() {
            document.querySelector("html").classList.remove('no-scroll');
            modalRegion.classList.toggle('is-active');
        });
    }
}

if (type) {
    type.forEach(function(el) {
        el.addEventListener('click', function() {
            document.querySelector("html").classList.add('no-scroll');
            modalType.classList.toggle('is-active');

        });
    });

    if (modalTypeClose) {
        modalTypeClose.addEventListener('click', function() {
            document.querySelector("html").classList.remove('no-scroll');
            modalType.classList.toggle('is-active');
        });
    }
}
