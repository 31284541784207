<template>
    <div class="calculator-item radio-style">
        <div>
            <label
            ><b>{{ itemData.name }}</b></label
            >
        </div>

        <div
            class="calculator-input"
            v-for="(radioItem, key) in itemData.radioitems"
        >
            <div class="radio-container">
                <label class="radio-label"
                ><b>{{ radioItem.name }}</b>
                    <input
                        type="radio"
                        v-model="valueRadio"
                        :value="key"
                    />

                    <span
                        class="radiobutton"
                        :style="{ color: mainColor }"
                    ></span>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        itemData: {
            type: Object,
            default: {}
        },
        mainColor: {
            type: String,
            default: '#000000'
        },
        subColor: {
            type: String,
            default: '#FFFFFF'
        }
    },
    computed: {
        valueRadio: {
            set(value) {
                this.$store.dispatch('saveVariableValue', {
                    name: this.itemData.variableName,
                    value
                });
            },
            get() {
                return this.$store.getters['variableValue'](this.itemData.variableName);
            }
        }
    },
    watch: {
        valueRadio(value) {
            this.$emit('change', {
                variableName: this.itemData.variableName,
                formula: this.itemData.radioitems[value]
            });
        }
    },
    methods: {},
    mounted() {
        // force select first input
        this.$store.dispatch('saveVariableValue', {
            name: this.itemData.variableName,
            value: Object.keys(this.itemData.radioitems)[0]
        });
    }
};
</script>
